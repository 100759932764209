import styled from "@emotion/styled";
import { PhotoCamera } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  Input,
  LinearProgress,
  Typography,
} from "@mui/material";

import { GetStsDecode } from "components/gql/queris/GetStsDecode";
import { GetUploadUrlQuery } from "components/gql/queris/GetUploadUrl";
import { GetAlertData } from "contexts/AlertContext";
import { GetCInfo } from "contexts/CompanyContext";
import React, { useState } from "react";

import { uploadFile } from "tools/requests";
import { ResponseParamsBlock } from "./ResponseButtonWithDialog";

const DragFileElement = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
`;

export default function DecodeSts(props) {
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [stsRes, setStsRes] = React.useState(false);
  const { setAlert } = GetAlertData();
  const [queryUpload] = GetUploadUrlQuery();
  const [stsDecodeQuery] = GetStsDecode();
  const [dragActive, setDragActive] = useState(false);
  const inputRef = React.useRef(null);
  const { cInfo } = GetCInfo();

  const handleChange = async function (e) {
    e.preventDefault();
    await changeImageArray(e.target.files);
    e.target.value = null;
    e.stopPropagation();
  };

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  // triggers when file is dropped
  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    changeImageArray(e.dataTransfer.files);
    e.dataTransfer.value = null;
  };

  async function changeImageArray(array) {
    if (array && array[0]) {
      showLoading();
      let { data } = await queryUpload({
        variables: {
          filename: array[0].name,
        },
      });
      if (data?.gibdd_uploadUrl?.fileID) {
        let success = await uploadFile(data?.gibdd_uploadUrl, array[0]);
        if (!success) {
          setAlert("Не удалось загрузить файл для расшифровки", "warning");
        } else {
          let stsData = await stsDecodeQuery({
            variables: {
              fileId: data?.gibdd_uploadUrl?.fileID,
            },
          });
          if (stsData?.data && stsData?.data?.gibdd_stsDecode?.grz) {
            let fileData = stsData?.data?.gibdd_stsDecode;
            props?.setFileData(fileData);
            setStsRes(fileData);
            if (fileData?.vin) {
              props?.setVin(fileData?.vin);
              props?.setVinText(fileData?.vin);
              props?.checkVin(fileData?.vin);
            }
            if (fileData?.grz) {
              props?.setPlate(fileData?.grz);
              props?.setPlateText(fileData?.grz);
            }
            if (fileData?.brand) {
              props?.setMarkText(fileData?.brand);
            }
            if (fileData?.model) {
              props?.setModelText(fileData?.model);
            }
            if (fileData?.year) {
              props?.setYearText(fileData?.year);
              props?.setYear(new Date("" + fileData?.year));
            }
          }
          if (stsData?.error) {
            setStsRes(stsData?.error);
            props?.setFileData(stsData?.error);
          }
        }
      }
      hideLoading();
    }
  }

  function showLoading() {
    setLoading(true);
  }

  function hideLoading() {
    setLoading(false);
  }

  const find = (cInfo?.channels ?? []).find((el) => {
    if (el?.name === "Gibdd_Sts") return true;
    else return false;
  });

  return (
    <React.Fragment>
      {find && (
        <React.Fragment>
          <input
            accept="image/*"
            alt="СТС"
            id={`input-file-upload-grz`}
            multiple={false}
            onChange={handleChange}
            ref={inputRef}
            style={{ display: "none" }}
            type="file"
          />

          <IconButton
            aria-hidden={false}
            disableRipple
            onClick={(e) => {
              setOpen(true);
            }}
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <PhotoCamera aria-hidden={false} />
          </IconButton>
          <Dialog
            maxWidth="sm"
            onClose={(e) => {
              setOpen(false);
            }}
            open={open}
          >
            <DialogContent>
              <Box
                id="form-file-upload"
                onDragEnter={handleDrag}
                onSubmit={(e) => e.preventDefault()}
              >
                <Input
                  id={`input-file-upload-grz`}
                  multiple={false}
                  onChange={handleChange}
                  ref={inputRef}
                  sx={{ display: "none" }}
                  type="image"
                />
                <label
                  className={
                    dragActive
                      ? "label-file-upload drag-active "
                      : "label-file-upload"
                  }
                  htmlFor={`input-file-upload-grz`}
                  id={`label-file-upload-grz`}
                >
                  <Typography>
                    Загрузите изображение свидетельства о регистрации ТС,
                    содержащее информацию об автомобиле
                  </Typography>
                </label>
                {dragActive && (
                  <DragFileElement
                    id={`drag-file-element ${props.variant}`}
                    onDragEnter={handleDrag}
                    onDragLeave={handleDrag}
                    onDragOver={handleDrag}
                    onDrop={handleDrop}
                  />
                )}
              </Box>
              {loading && <LinearProgress />}
              <ResponseParamsBlock data={stsRes} name={"stsRes"} />
            </DialogContent>

            <Button
              onClick={(e) => {
                setOpen(false);
              }}
              sx={{ margin: "0.4rem" }}
              variant="outlined"
            >
              ОК
            </Button>
          </Dialog>
        </React.Fragment>
      )}
    </React.Fragment>
  );
}
